import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import PreviewableImage from '../components/Preview/PreviewableImage'
import { theme } from '../styles'
import { Layout, Wrapper, PageSliceZone, SEO } from '../components'
import website from '../../config/website'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

const Hero = styled.div`
  background-color: ${theme.colors.white};
  padding-top: ${theme.spacing(24)};
  padding-bottom: ${theme.spacing(6)};
  padding: ${theme.spacing(16)} ${theme.spacing(3)} ${theme.spacing(6)};
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  text-align: center;
`

const Headline = styled.h2`
  font-size: 4rem;
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 3.5rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 3rem;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 2.5rem;
  }
`

const SubHeadline = styled.p`
  font-size: 3.5rem;
  @media (max-width: ${theme.breakpoints.ms}) {
    font-size: 2.9rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 2.4rem;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 2.2rem;
  }
`

const HeroImage = styled(PreviewableImage)`
  min-height: 50vh;
  border: 2px solid red;
`

const PageWrapper = Wrapper.withComponent('main')

const Page = ({ data: { prismicPage }, location, preview }) => {
  const { data } = prismicPage
  return (
    <Layout customSEO>
      <Amplitude
        eventProperties={{
          scope: ['page'],
          title: data.title,
          url: location.pathname,
        }}
      >
        <LogOnMount eventType="page view" />
        <SEO
          title={
            preview
              ? `Previsualizando: ${data.title} | ${website.titleAlt}`
              : `${data.title} | ${website.titleAlt}`
          }
          pathname={location.pathname}
          desc={data.description}
          node={prismicPage}
          page
        />

        <Hero>
          <Headline>{data.headline.text}</Headline>
          {data.subtitle && <SubHeadline>{data.subtitle}</SubHeadline>}
        </Hero>
        {data.featured_image.url && (
          <HeroImage image={data.featured_image} placeholderHeight={500} minFluidHeight="60vh" />
        )}
        <PageWrapper>
          <PageSliceZone id={website.skipNavId} allSlices={data.body} />
        </PageWrapper>
      </Amplitude>
    </Layout>
  )
}

export default Page

Page.propTypes = {
  data: PropTypes.shape({
    prismicPage: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  preview: PropTypes.bool,
}

Page.defaultProps = {
  preview: false,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        title
        headline {
          text
        }
        subtitle
        description
        featured_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560, maxHeight: 800, quality: 90, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicPageBodyText {
            slice_type
            id
            primary {
              section_heading {
                text
              }
              section_subtitle
              text {
                html
                text
              }
              action_link {
                link_type
                url
                target
              }
              action_label
              action_type
            }
          }
          ... on PrismicPageBodyImage {
            slice_type
            id
            primary {
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              image_position
            }
          }
          ... on PrismicPageBodyTextWithImage {
            slice_type
            id
            primary {
              section_heading {
                text
              }
              section_subtitle
              text {
                html
                text
              }
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              image_position
              action_link {
                link_type
                url
                target
              }
              action_label
              action_type
            }
          }
          ... on PrismicPageBodyCards {
            slice_type
            id
            primary {
              cards_per_row
            }
            items {
              card_title {
                text
              }
              card_subtitle
              card_content {
                html
                text
              }
              card_image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 720, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              card_action_link {
                target
                url
                link_type
              }
              card_action_link_label
              card_action_link_type
            }
          }
          ... on PrismicPageBodyCardsWithImage {
            slice_type
            id
            primary {
              image_position
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 720, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            items {
              card_title {
                text
              }
              card_subtitle
              card_content {
                html
                text
              }
              card_image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 256, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              card_action_link {
                target
                url
                link_type
              }
              card_action_link_label
              card_action_link_type
            }
          }
        }
      }
    }
  }
`
