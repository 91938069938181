import React from 'react'
import { usePrismicPreview } from 'gatsby-source-prismic'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../styles'
import Post from '../templates/post'
import Page from '../templates/page'
import SEO from '../components/SEO'
import linkResolver from '../gatsby/linkResolver'
import website from '../../config/website'
import Spinner from '../components/Preview/Spinner'

const PageWrapper = styled.div`
  width: 100%;
  height: 60vh;
  margin-top: 40vh;
  padding: ${theme.spacing(10)};
  text-align: center;
`

const Title = styled.h1``

const Paragraph = styled.p``

const LoadingPreview = ({ search }) => (
  <PageWrapper>
    <SEO title={`Previsualizaciones | ${website.titleAlt}`} />
    {search ? (
      <Spinner width={256}></Spinner>
    ) : (
      <React.Fragment>
        <Title> * * *</Title>
        <Paragraph>Puedes lanzar una previsualización desde el gestor de contenidos</Paragraph>
      </React.Fragment>
    )}
  </PageWrapper>
)

LoadingPreview.propTypes = {
  search: PropTypes.bool.isRequired,
}

const FailedPreview = () => (
  <PageWrapper>
    <SEO title={`Previsualizaciones | ${website.titleAlt}`} />
    <Title>{`{ }`}</Title>
    <Paragraph>Este tipo de documento no admite previsualización</Paragraph>
  </PageWrapper>
)

function PreviewTemplate(props) {
  const { type, previewData } = props
  switch (type) {
    case 'prismicPost':
      return <Post data={previewData} preview {...props} />
    case 'prismicPage':
      return <Page data={previewData} preview {...props} />
    default:
      return <FailedPreview />
  }
}

PreviewTemplate.propTypes = {
  type: PropTypes.string.isRequired,
  previewData: PropTypes.object.isRequired,
}

const PreviewPage = props => {
  const { location } = props

  // https://github.com/angeloashmore/gatsby-source-prismic/issues/121
  const pathResolver = () => doc => linkResolver(doc)

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: 'tao-prismic-test',
    linkResolver: pathResolver,
  })

  return previewData && path ? (
    <PreviewTemplate type={Object.keys(previewData)[0]} previewData={previewData} {...props} />
  ) : (
    <LoadingPreview search={!!location.search} />
  )
}

export default PreviewPage

PreviewPage.propTypes = {
  location: PropTypes.object.isRequired,
}
