import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import PreviewableImage from '../components/Preview/PreviewableImage'
import { theme } from '../styles'
import { Layout, Articles, Wrapper, PostSliceZone, Title, SEO } from '../components'
import Categories from '../components/Homepage/Articles/Categories'
import website from '../../config/website'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

const Hero = styled.div`
  background-color: ${theme.colors.white};
  padding-top: ${theme.spacing(12)};
  max-width: ${theme.maxWidthText};
`

const Headline = styled.h2`
  display: inline;
`

const SubHeadline = styled.h3`
  display: inline;
`

const Meta = styled.div`
  margin-bottom: 1.45rem;
  font-family: 'TeleGroteskNext-Thin';
  font-size: 1rem;
  a {
    font-style: normal;
    color: black;
    font-weight: 300;
  }
`
const PostWrapper = Wrapper.withComponent('main')

const Post = ({ data: { prismicPost, posts }, location, preview }) => {
  const { data, uid } = prismicPost

  let categories = false
  if (data.categories[0].category.document) {
    categories = data.categories.map(c => c.category.document)
  }
  return (
    <Layout customSEO>
      <Amplitude
        eventProperties={{
          scope: ['post'],
          title: data.title.text,
          url: location.pathname,
        }}
      >
        <LogOnMount eventType="post view" />
        <SEO
          title={
            preview
              ? `Previsualizando: ${data.title.text} | ${website.titleAlt}`
              : `${data.title.text} | ${website.titleAlt}`
          }
          pathname={location.pathname}
          desc={data.description}
          node={prismicPost}
          banner={prismicPost.data.featured_image.url}
          article
        />
        <PostWrapper>
          <Hero>
            <Headline>{data.title.text}</Headline> <SubHeadline>{data.description}</SubHeadline>
            <Meta>
              <Amplitude
                eventProperties={inheritedProps => ({
                  ...inheritedProps,
                  scope: [...inheritedProps.scope, 'meta'],
                })}
              >
                {data.date}
                {categories && (
                  <>
                    {' — '}
                    <Categories categories={categories} pid={uid} />
                  </>
                )}
              </Amplitude>
            </Meta>
          </Hero>
          <PreviewableImage image={data.featured_image} placeholderHeight={500} />
          <PostSliceZone id={website.skipNavId} allSlices={data.body} />
          {posts && (
            <React.Fragment>
              <Title style={{ marginTop: '4rem' }}>Otros artículos</Title>
              <Articles posts={posts.edges} />
            </React.Fragment>
          )}
        </PostWrapper>
      </Amplitude>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  preview: PropTypes.bool,
}

Post.defaultProps = {
  preview: false,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        description
        date(formatString: "DD.MM.YYYY")
        categories {
          category {
            document {
              ... on PrismicCategory {
                type
                slugs
                data {
                  name
                }
              }
            }
          }
        }
        featured_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostBodyCodeBlock {
            slice_type
            id
            primary {
              code_block {
                html
              }
            }
          }
          ... on PrismicPostBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(
      limit: 3
      sort: { fields: [data___date], order: DESC }
      filter: { uid: { ne: $uid } }
    ) {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            featured_image {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1440, maxHeight: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    type
                    slugs
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
